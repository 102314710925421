
















































































































































































import {
  ref,
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

import { useTransitions } from '@/router/transitions'

import { Order, User } from '@/types/api'

// Store
import { useStore } from '@/store'
import { GETTERS, MODULES } from '@/store/types'

import { formatDistanceFromDateToNow, formatDateWithMask } from '@/utils/dates'

import { useLoadUserHook } from '@/shared/hooks'

interface Props extends Record<string, any> {
  readonly order: Order
}

export default defineComponent<Props>({
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { order } = toRefs(props)

    const user = ref<User>()

    const now = new Date()

    const store = useStore()

    const { goToOrder, goToWorker } = useTransitions()

    const companyHasOrder = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.companyHasOrder}`](order.value.id))
    const isCurrentUserContractor = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.getUserId}`] === order.value.contractor_id)

    const timeLast = computed(() => formatDistanceFromDateToNow(order.value.end_dt))

    const workerRoute = computed(() => ({ name: 'Workers', params: { id: order.value.contractor_id.toString() } }))

    useLoadUserHook(order.value.contractor_id, user)

    return {
      now,

      user,
      timeLast,
      goToOrder,
      goToWorker,
      workerRoute,
      formatDateWithMask,

      companyHasOrder,
      isCurrentUserContractor,
    }
  },
})

