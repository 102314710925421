

















































import {
  ref,
  watch,
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { createMachine } from '@xstate/fsm'

import { RawLocation } from 'vue-router/types'

import { useI18n } from '@/i18n'

import { Company, Offer, User } from '@/types/api'

import { Routes } from '@/router'

import { ordersProvider } from '@/providers/orders'
import { companiesRepository } from '@/repositories/companies'

import { useLoadUserHook } from '@/shared/hooks'

import { useMachine } from '@/utils/state-machine'

interface Props extends Record<string, any> {
  readonly offer: Offer
}

const machine = createMachine({
  id: 'offer-card-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent<Props>({
  components: {},

  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { offer } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()
    const { state: loadingState, send } = useMachine(machine)

    const loading = computed(() => loadingState.value.matches('active'))

    const user = ref<User>()

    const company = ref<Company>()

    const errors = ref({})

    useLoadUserHook(offer.value.user_id, user)

    const workerRoute = computed<RawLocation>(() => {
      if (!user.value) { return {} }

      return {
        name: Routes.of.Workers.OVERVIEW,
        params: { id: user.value.id.toString() },
      }
    })

    const companyRoute = computed<RawLocation>(() => {
      if (!company.value) { return {} }

      return {
        name: Routes.of.Companies.OVERVIEW,
        params: { id: company.value.id.toString() },
      }
    })

    async function loadCompany(companyId?: number | string) {
      if (companyId == null || typeof companyId === 'undefined') { return }
      company.value = await companiesRepository.find({ id: Number(companyId) })
    }

    // company.value?.

    async function onOfferApply() {
      if (offer.value.accepted) {
        toast.warning(i18n.t('ui.errors.trying_to_broke_site'))
        return
      }

      try {
        send('TOGGLE')

        const result = await ordersProvider.applyOffer(
          { id: offer.value.order_id },
          { event: 'start', offer_id: offer.value.id },
        )

        if (process.env.NODE_ENV === 'development') {
          console.log({ result })
        }

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        send('TOGGLE')
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.offer_accepted'))
      offer.value.accepted = true
      emit('accepted')
    }

    function onFailSubmit() {
      offer.value.accepted = true
      toast.error(i18n.t(errors.value.toString()))
      emit('fail')
    }

    watch(
      () => user.value?.company_id,
      loadCompany,
    )

    return {
      user,
      company,

      workerRoute,
      companyRoute,

      loading,
      onOfferApply,
    }
  },
})

