














import {
  defineComponent,
} from 'vue-demi'

import OrderItem from '@/components/order/OrderItem.vue'
import OrderItemMobile from '@/components/order/OrderItemMobile.vue'

interface Props extends Record<string, any> {
  readonly id: string
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    OrderItem,
    OrderItemMobile,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    section: {
      type: String,
      default: '',
    },
  },
})

