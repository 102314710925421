








































































































































































































































































import {
  ref,
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

import { Order, User } from '@/types/api'

import { useStore } from '@/store'
import { MODULES, ACTIONS, GETTERS } from '@/store/types'
import { processStoreActions } from '@/store/utils'

import { useLoadUserHook } from '@/shared/hooks'
import { formatDistanceFromDateToNow, formatDateWithMask } from '@/utils/dates'

import PreviewModal from '@/components/widgets/PreviewModal.vue'
import MakeOfferDialog from '@/components/dialogs/MakeOfferDialog.vue'

interface Props extends Record<string, any> {
  readonly order: Order
  readonly loading: boolean
}

export default defineComponent<Props>({
  components: {
    PreviewModal,
    MakeOfferDialog,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props) {
    const { order } = toRefs(props)

    const store = useStore()

    const userId = computed(() => store.state.Profile.user?.id)

    const companyIsCustomer = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.companyIsCustomer}`])

    const companyIsContractor = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.companyIsContractor}`])

    const contractor = ref<User>()

    const isCurrentUserAuthor = computed(() => order.value?.customer_id === userId.value)

    const isCurrentUserContractor = computed(() => order.value?.contractor_id === userId.value)

    function onSendOffer() {
      store.dispatch({
        type: `${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`,
      }).then(() => processStoreActions([
        `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
      ]))
    }

    useLoadUserHook(order.value.customer_id, contractor)

    return {
      contractor,

      onSendOffer,

      companyIsCustomer,
      companyIsContractor,

      isCurrentUserAuthor,
      isCurrentUserContractor,
      formatDistanceFromDateToNow,
      formatDateWithMask,
    }
  },
})

