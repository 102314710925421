























































































import {
  ref,
  toRefs,
  reactive,
  defineComponent,
  computed,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
// @ts-ignore
import { mask } from 'vue-the-mask'

// Types
import { OfferCreateInput } from '@/types/api'
import { VForm } from '@/types/vuetify'

// Localization
import { useI18n } from '@/i18n'

// Store
import { useStore } from '@/store'
import { GETTERS, MODULES } from '@/store/types'

import { ordersProvider } from '@/providers/orders'

import { rules, unValidateForm, validateForm } from '@/utils/validators'

import VCurrencyField from '@/components/widgets/VCurrencyField.vue'
import DragAndDropZone from '@/components/widgets/DragAndDropZone.vue'

interface Props extends Record<string, any> {
  readonly orderId: number
  readonly loading: boolean
}

export default defineComponent<Props>({
  directives: {
    mask,
  },

  components: {
    VCurrencyField,
    DragAndDropZone,
  },

  props: {
    orderId: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { orderId } = toRefs(props)

    const i18n = useI18n()
    const store = useStore()
    const toast = useToast()

    const companyHasOrder = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.companyHasOrder}`](orderId.value))

    const formRef = ref<VForm>()

    const state = reactive({
      modal: false,
      loading: false,
    })

    const offer = reactive<OfferCreateInput>({
      description: '',
      price: 0,
      files: [],
    })

    const errors = ref({})

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        state.loading = true

        await ordersProvider.makeOffer(
          { id: orderId.value },
          offer,
        )

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        onFailSubmit()
      } finally {
        state.loading = false
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.offer_sent'))
      state.modal = false

      resetForm()

      emit('success')
    }

    function onFailSubmit() {
      toast.error(i18n.t(errors.value.toString()))

      emit('fail')
    }

    function resetForm() {
      unValidateForm(formRef.value)
      offer.description = ''
      offer.price = 0
    }

    return {
      state,
      formRef,

      offer,
      companyHasOrder,

      rules,
      onSubmit,
    }
  },
})

