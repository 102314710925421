import { RawLocation } from 'vue-router/types'

import { Scalars } from '@/types'

import { useRouter, Routes } from '.'

export function useTransitions() {
  const router = useRouter()

  function goToOrder(id: Scalars['ID'], query?: Record<string, string>) {
    return router.push({
      name: Routes.of.Orders.OVERVIEW,
      params: { id: id.toString() },
      query,
    })
  }

  function goToWorker(id: Scalars['ID'], query?: Record<string, string>) {
    return router.push({
      name: Routes.of.Workers.OVERVIEW,
      params: { id: id.toString() },
      query,
    })
  }

  return {
    goToOrder,
    goToWorker,
  }
}

export function useLocations() {
  function toServiceList(query?: Record<string, string>): RawLocation {
    return {
      name: Routes.of.Services.LIST,
      query,
    }
  }

  return {
    toServiceList,
  }
}
