






























import {
  ref,
  toRefs,
  reactive,
  onMounted,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { Offer } from '@/types/api'

import { ordersProvider } from '@/providers/orders'

import OfferCard from '@/components/OfferCard.vue'

interface Props extends Record<string, any> {
  readonly orderId: number
  readonly disabled: boolean
}

export default defineComponent<Props>({
  components: {
    OfferCard,
  },

  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { orderId } = toRefs(props)

    const toast = useToast()

    const offersList = ref<Offer[]>()

    const state = reactive({
      loading: false,
    })

    async function loadOffers() {
      state.loading = true

      try {
        const { results } = await ordersProvider.getOffers({ id: orderId.value })
        offersList.value = results
      } catch (error) {
        toast.error(`loadOrder: ${error.error}`)
      } finally {
        state.loading = false
      }
    }

    onMounted(loadOffers)

    return {
      state,
      offersList,

      loadOffers,
    }
  },
})

