






















































































































































































import {
  inject,
  watch,
  computed,
  reactive,
  defineComponent,
  ref,
  nextTick,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { OrdersOrderByParams } from '@/types/api'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { useRouter, useRoute } from '@/router'

import { useStore } from '@/store'
import { ACTIONS, GETTERS, MODULES } from '@/store/types'

import isIntoView from '@/utils/isIntoView'

import OrderCard from '@/components/OrderCard.vue'
import OrderCardMobile from '@/components/MobileOrderCard.vue'
import CategoriesMenu from '@/components/CategoriesMenu.vue'

interface Props extends Record<string, any> {
  readonly foo: string
}

interface Category {
  id?: number
  key: string
  title: string
}

const categories: Category[] = [
  { id: 0, key: 'all', title: 'все категории' },
  { id: 1, key: 'all', title: 'все категории' },
  { id: 2, key: 'all', title: 'все категории' },
  { id: 3, key: 'all', title: 'все категории' },
  { id: 4, key: 'all', title: 'все категории' },
]

export default defineComponent<Props>({
  props: {},

  components: {
    OrderCard,
    OrderCardMobile,
    CategoriesMenu,
    FadeTransition,
  },

  setup(_, { root }) {
    const toast = useToast()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      menu: false,
      filters: {
        categories: [],
      },
    })

    const routeWithParams = ref(root.$route.query)

    const page = ref(1)
    const search = ref()

    const lastQueryId = ref<any>(undefined)
    const newQueryId = ref<any>(undefined)

    const companyHasWorks = computed(() => store.getters[`${MODULES.Profile}/${GETTERS.Profile.companyHasWorks}`])

    const isNotTablet = inject<boolean>('isNotTablet')

    const orderList = computed(() => (store.state.Orders.list ? store.state.Orders.list : []))
    const completedQueries = ref<number[]>([])

    const orderParams = reactive<{
      orderBy: OrdersOrderByParams
    }>({
      orderBy: {},
    })

    // @ts-ignore
    // eslint-disable-next-line
    async function loadOrders(orderBy: OrdersOrderByParams, search: string, newPage?: number, changeOrder = false) {
      try {
        await store.dispatch(`${MODULES.Orders}/${ACTIONS.Orders.loadList}`, {
          page: newPage, orderBy, changeOrder, search,
        })
        await setScrollListener()
      } catch (error) {
        toast.error(`loadOrders: ${error}`)
      }
    }

    function onToggleOrderBy(key: string) {
      const currentValue = orderParams.orderBy[key] || route.query[key]

      if (!key) { return }

      if (!currentValue) {
        orderParams.orderBy[key] = 'ASC'
      } else {
        orderParams.orderBy[key] = currentValue === 'ASC' ? 'DESC' : 'ASC'
      }

      page.value = 1
      router.replace({ query: orderParams.orderBy })
    }

    async function setScrollListener() {
      if (document.querySelectorAll('.order-card').length && document.querySelectorAll('.order-card').length > 10) {
        document.querySelector('.v-application--wrap')?.addEventListener('scroll', setScrollListener)
        if (document.querySelectorAll('.order-card')[document.querySelectorAll('.order-card').length - 3] && isIntoView(document.querySelectorAll('.order-card')[document.querySelectorAll('.order-card').length - 3])) {
          document.querySelector('.v-application--wrap')?.removeEventListener('scroll', setScrollListener)

          const previousLastQueryId = lastQueryId.value

          nextTick(async () => {
            lastQueryId.value = orderList.value[orderList.value.length - 1]?.id

            if (newQueryId.value !== lastQueryId.value) {
              page.value += 1
              await loadOrders(root.$route.query as OrdersOrderByParams, search.value, page.value)
            } else {
              page.value -= 1
            }

            newQueryId.value = lastQueryId.value

            if (previousLastQueryId !== lastQueryId.value) {
              lastQueryId.value = orderList.value[orderList.value.length - 1]?.id
              document.querySelector('.v-application--wrap')?.addEventListener('scroll', setScrollListener)
            }
          })
        }
      }
    }

    watch(
      () => root.$route,
      v => loadOrders(v.query as OrdersOrderByParams, search.value, 1, true),
      { deep: true, immediate: true },
    )

    return {
      orderList,
      categories,

      page,
      search,
      routeWithParams,
      lastQueryId,
      newQueryId,

      companyHasWorks,

      isNotTablet,
      onToggleOrderBy,
      ...state,
      loadOrders,
      completedQueries,
    }
  },
})

