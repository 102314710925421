






























































































import {
  ref,
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'
import { RawLocation } from 'vue-router/types'
// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import { Company, Order, User } from '@/types/api'

import { Routes } from '@/router'

import { usersRepository } from '@/repositories/users'
import { ordersRepository } from '@/repositories/orders'
import { companiesRepository } from '@/repositories/companies'

import WorkerCard from '@/components/WorkerCard.vue'

interface Props extends Record<string, any> {
  readonly orderId: number
}

export default defineComponent<Props>({
  components: {
    FadeTransition,
    WorkerCard,
  },

  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { orderId } = toRefs(props)

    const toast = useToast()

    const state = reactive({
      loading: false,
    })

    const contractor = ref<User>()
    const order = ref<Order>()
    const company = ref<Company>()

    const errors = ref({})

    const workerRoute = computed<RawLocation>(() => {
      if (!contractor.value) { return {} }

      return {
        name: Routes.of.Workers.OVERVIEW,
        params: { id: contractor.value.id.toString() },
      }
    })

    const companyRoute = computed<RawLocation>(() => {
      if (!company.value) { return {} }

      return {
        name: Routes.of.Companies.OVERVIEW,
        params: { id: company.value.id.toString() },
      }
    })

    async function loadEntities() {
      try {
        order.value = await ordersRepository.find({ id: orderId.value })
        if (order.value.contractor_id == null) { return }
        contractor.value = await usersRepository.find({ id: order.value.contractor_id })
        if (contractor.value.company_id == null) { return }
        company.value = await companiesRepository.find({ id: contractor.value.company_id })
      } catch (error) {
        toast.error(error.toString())
      }
    }

    watch(
      () => orderId.value,
      loadEntities,
      { immediate: true },
    )

    return {
      state,
      contractor,
      order,
      company,
      errors,
      workerRoute,
      companyRoute,
    }
  },
})

