




























import { defineComponent } from 'vue-demi'
import { useVModel } from '@vueuse/core'

import { TabItem } from '@/shared/tabs'

interface Props extends Record<string, any> {
  readonly orderId: number
  readonly controls: TabItem[]
  readonly section: string
}

export default defineComponent<Props>({
  components: {},

  props: {
    orderId: {
      type: Number,
      required: true,
    },

    controls: {
      type: Array,
      required: true,
    },

    section: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const sectionModel = useVModel(props, 'section', emit)

    return {
      sectionModel,
    }
  },
})

