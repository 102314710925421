














































































































































import {
  ref,
  watch,
  inject,
  toRefs,
  reactive,
  computed,
  onMounted,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { Order, Work } from '@/types/api'

import { useI18n } from '@/i18n'
import { useStore } from '@/store'

import { worksRepository } from '@/repositories/works'
import { ordersRepository } from '@/repositories/orders'

import { TabItem, useTabs } from '@/shared/tabs'
import { formatDistanceFromDateToNow, formatDateWithMask } from '@/utils/dates'

import MakeOfferDialog from '@/components/dialogs/MakeOfferDialog.vue'
import OrderCustomerControls from '@/components/order/OrderCustomerControls.vue'

import OrderOffers from '@/components/order/OrderOffers.vue'
import OrderOverview from '@/components/order/OrderOverview.vue'
import OrderContractors from '@/components/order/OrderContractors.vue'

interface Props extends Record<string, any> {
  readonly id: string
  readonly section: string
}

interface Category {
  id?: number
  key: string
  title: string
}

const categories: Category[] = [
  { id: 0, key: 'all', title: 'все категории' },
  { id: 1, key: 'all', title: 'все категории' },
  { id: 2, key: 'all', title: 'все категории' },
  { id: 3, key: 'all', title: 'все категории' },
  { id: 4, key: 'all', title: 'все категории' },
]

export default defineComponent<Props>({
  components: {
    MakeOfferDialog,
    OrderCustomerControls,
    contractors: OrderContractors,
    offers: OrderOffers,
    overview: OrderOverview,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    section: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { id, section } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()
    const store = useStore()

    const items: TabItem[] = [
      {
        icon: 'mdi-account',
        title: i18n.t('ui.order.overview'),
        component: 'overview',
      },
      {
        icon: 'mdi-access-point',
        title: i18n.t('ui.order.offers'),
        component: 'offers',
      },
      {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t('ui.order.contractors'),
        component: 'contractors',
      },
    ]

    const userId = computed(() => store.state.Profile.user?.id)

    const isNotTablet = inject<boolean>('isNotTablet')

    const work = ref<Work>()

    const order = ref<Order>()

    const tab = useTabs(items, section)

    const isCurrentUserAuthor = computed(() => order.value?.customer_id === userId.value)

    const isCurrentUserContractor = computed(() => order.value?.contractor_id === userId.value)

    const state = reactive({
      loading: false,
      filters: {
        categories: [],
      },
    })

    async function loadOrder() {
      state.loading = true

      try {
        order.value = await ordersRepository.find({ id: id.value })
        work.value = await worksRepository.find({ id: order.value.work_id })
      } catch (error) {
        toast.error(`loadOrder: ${error.error}`)
      } finally {
        state.loading = false
      }
    }

    watch(
      () => id.value,
      loadOrder,
      { immediate: true },
    )

    onMounted(loadOrder)

    return {
      tab,

      work,
      order,

      items,
      categories,

      isNotTablet,
      isCurrentUserAuthor,
      isCurrentUserContractor,

      loadOrder,

      formatDateWithMask,
      formatDistanceFromDateToNow,

      state,
    }
  },
})

