import { computed, Ref } from 'vue-demi'
import VueI18n from 'vue-i18n'

import { useRouter } from '@/router'

export interface TabItem {
  id?: number
  icon?: string
  title: VueI18n.TranslateResult
  component: string
}

// eslint-disable-next-line import/prefer-default-export
export function useTabs(items: TabItem[], section: Ref<string>) {
  const router = useRouter()

  return computed({
    get: () => {
      let index = 0

      console.log({ section: section.value })

      const itemIndex = items.findIndex(item => item.component === section.value)
      if (itemIndex !== -1) { index = itemIndex }

      return index
    },

    set: index => {
      router.push({
        params: { section: items[index].component },
      })
    },
  })
}
