






















































import {
  reactive,
  defineComponent,
} from 'vue-demi'

interface Props extends Record<string, any> {
  readonly file: string
}

export default defineComponent({
  props: {
    file: {
      type: String,
      default: null,
    },
  },

  setup() {
    const state = reactive({
      dialog: false,
    })

    return { state }
  },
})

